// CB

$body-selection-text-color: #fff !default;

// Badge
$badge-default-color: #fff !default;
$badge-default-bg: $color-brand-primary !default;
$badge-padding: 0.2em 0.8em 0.3em !default;

// Header Top

$header-logo-aspect-ratio: 6.72 !default;
$header-logo-vertical-margin: 8px !default;
$header-logo-vertical-margin--tablet: 15px !default;

$header-top-text-color: #fff !default;

$header-top-brand-width--title: 26px !default;
$header-top-drawer-toggle-text-color: #fff !default;
$header-top-offer-link-text-color: #fff !default;
$header-top-navigation-item-text-color: #fff !default;
$header-top-navigation-item-text-color--hover: #fff !default;
$header-top-navigation-search-form-text-color: #fff !default;

// Header Drawer
$header-drawer-navigation-list-background-color--nav-active: $color-brand-primary !default;
$header-drawer-navigation-item-background-color--nav: $color-brand-primary !default;
$header-drawer-navigation-item-text-color--nav: #fff !default;
$header-drawer-navigation-item-text-color--nav-nav: #fff !default;
$header-drawer-navigation-item-text-color--nav-active: #fff !default;

$header-drawer-passport-icon-link-text-color: #fff !default;

// Cover definitions
$block-cover-brand-width--title: 87px !default;

$block-cover-article-overlay-gradient--tablet-article-1: 135deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.34) 59%, rgba(45, 25, 16, 0.28) 67%, rgba(255, 118, 59, 0.15) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-2: 135deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0.34) 59%, rgba(45, 25, 16, 0.28) 67%, rgba(255, 118, 59, 0.15) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-3: 135deg, rgba(0,0,0,0.92) 0%, rgba(255,118,59,0.34) 59%, rgba(255,118,59,0.28) 67%, rgba(255,118,59,0.15) 100% !default;
$block-cover-article-overlay-gradient--tablet-article-4: 135deg, rgba(0,0,0,0.92) 0%, rgba(255,118,59,0.34) 59%, rgba(255,118,59,0.28) 67%, rgba(255,118,59,0.15) 100% !default;

// Ad definitions
$ad-horizontal-height: 0px !default;

// Hero  definitions
$tag-color: $color-brand-secondary !default;
$hero-font-family: $font-heading-xbold !default;

$article-body-font-size: 17px !default;
$article-body-line-height: 1.55 !default;

// Visor Block
$block-header-font-family: $font-heading-bold !default;

// Visor Article
$bullet-tag-color: #A3A3A3 !default;
$bullet-title-font-size--large--tablet: 28px !default;
$bullet-title-featured-line-height: 1.0 !default;
$bullet-tag-font-family: $font-heading-bold !default;
$bullet-tag-font-family: $font-heading-bold !default;
$bullet-summary-comments-color: #fff !default;
$quote-pull-border-width: 5px !default;
$quote-pull-padding: 5px 25px !default;
$quote-pull-padding--left: 0 40px 0 0 !default;
$quote-pull-padding--right: 0 0 0 40px !default;

// Buttons
$btn-vote-bg: $color-brand-primary !default;
$btn-vote-color: #fff !default;
$btn-load-more-text-color: #969696 !default;
$btn-load-more-background-color: #191919 !default;
$btn-load-more-border-color: $color-brand-primary !default;

$btn-read-more-background-color: $color-brand-primary !default;

// Taxonomy
$taxonomy-pager-list-item-background-color: #484848 !default;
$taxonomy-pager-list-item-color: #fafafa !default;
$taxonomy-pager-list-item-color--current: #fafafa !default;

// Misc
$border-radius: 7px !default;
