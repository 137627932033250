$site: 'cb' !default;
$sitefull: 'crackberry' !default;
$sitedomain: 'crackberry.com' !default;

// Brand
$color-brand-primary: #FF753B !default;
$color-brand-primary-text: #fff !default;
$color-brand-secondary: #1693CA !default;
$color-brand-secondary-text: #fff !default;
$color-brand-tertiary: $color-brand-secondary !default;
$color-brand-tertiary-text: $color-brand-secondary-text !default;

// Font definitions
$font-map: (
        'le-monde-livre-book': (
                font-family: 'le-monde-livre-std',
                font-weight: 300
        ),
        'le-monde-livre-bold': (
                font-family: 'le-monde-livre-std',
                font-weight: 700
        ),
        'proxima-nova-regular': (
                font-family: 'proxima-nova',
                font-weight: 400
        ),
        'proxima-nova-semibold': (
                font-family: 'proxima-nova',
                font-weight: 600
        ),
        'proxima-nova-bold': (
                font-family: 'proxima-nova',
                font-weight: 700
        )

) !default;

// Font definitions
$font-paragraph: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-extra-light: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-light: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-book: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-medium: "le-monde-livre-book", Palatino, Times, serif !default;
$font-paragraph-semibold: "le-monde-livre-bold", Palatino, Times, serif !default;
$font-paragraph-bold: "le-monde-livre-bold", Palatino, Times, serif !default;
$font-heading-light: "proxima-nova-regular", Helvetica, Arial, sans-serif !default;
$font-heading-regular: "proxima-nova-regular", Helvetica, Arial, sans-serif !default;
$font-heading-semibold: "proxima-nova-semibold", Helvetica, Arial, sans-serif !default;
$font-heading-bold: "proxima-nova-bold", Helvetica, Arial, sans-serif !default;
$font-heading-xbold: "proxima-nova-bold", Helvetica, Arial, sans-serif !default;
$font-default: $font-paragraph-medium !default;